import '@styles/main.scss';
import menu from './components/menu';
import WorkFilter from './components/work-filter';
import ServicesFilter from './components/services-filter';
import Artists from './components/artists';

class App {
    constructor() {
        menu();
        new WorkFilter();
        new ServicesFilter();
        new Artists();
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});
