export default function () {

    const hamburger = document.getElementById('menu-button');

    if (!hamburger) {
        return;
    }

    const menu = document.getElementById('menu-primary-navigation'),
        overlay = document.getElementById('menu-overlay'),
        menuItem = document.querySelectorAll('.menu-item'),
        hamburgerText = document.getElementById('menu-button-text');

    const close = () => {
        menu?.classList.remove('is-active');
        hamburger.classList.remove('is-active');
        overlay?.classList.remove('is-active');
        document.documentElement.classList.remove('has-menu-open');
    };

    hamburger.addEventListener('click', () => {
        menu?.classList.toggle('is-active');
        hamburger.classList.toggle('is-active');
        overlay?.classList.toggle('is-active');
        document.documentElement.classList.toggle('has-menu-open');

        if(hamburger.classList.contains('is-active')) {
            hamburgerText.textContent = 'CLOSE'
        } else {
            hamburgerText.textContent = 'MENU'
        }
    });

    menuItem.forEach(item => {
        item.addEventListener('mouseover', () => {
            item.classList.add('is-active')
        });

        item.addEventListener('mouseleave', () => {
            item.classList.remove('is-active')
        })
    });
}
