import Muuri from 'muuri';
import type Grid from 'muuri';

export default class WorkFilter {
    private grid?: Grid;

    constructor() {
        const newsPostWrapper = document.querySelector('.work-item-wrapper');
        
        if (newsPostWrapper) {
            this.grid = new Muuri('.work-item-wrapper');
        }

        const filterElements = document.querySelectorAll('.category__item-span');

        const close = () => {
            filterElements.forEach((item) => {
                item.classList.remove('active');
            });
        };

        filterElements.forEach((item) => {
            item.addEventListener('click', () => {
                close();
                const selectedItem = item.innerHTML.toLowerCase().replace(/ /g, '-').replace(/-&amp;/g, '');
                console.log(selectedItem);

                item.classList.add('active');

                if (selectedItem === 'all') {
                    this.grid.filter('.muuri-item');
                } else {
                    this.grid.filter((item) => {
                        const element = item.getElement();
                        if (element) {
                            const categories = element.getAttribute('data-alias');
                            return categories?.includes(selectedItem) || false;
                        }
                        return false;
                    });
                }
            });
        });
    }
}