export default class Artists {
    constructor() {
        const artistOne = document.getElementById('artist--one');
        const artistTwo = document.getElementById('artist--two');
        const wrapper = document.getElementById('artist-widget-wrapper');

        if (!artistOne) { return; }
        if (!artistTwo) { return; }
        if (!wrapper) { return; }

        artistOne?.addEventListener('click', () => {
            if (artistOne.classList.contains('is-active')) {
                artistOne.classList.remove('is-active');
                artistTwo.style.display = 'block';
                wrapper.classList.remove('is-open');
            } else {
                artistOne.classList.add('is-active');
                artistTwo.style.display = 'none';
                wrapper?.classList.add('is-open');
            }
        });

        artistTwo?.addEventListener('click', () => {
            if (artistTwo.classList.contains('is-active')) {
                artistTwo.classList.remove('is-active');
                artistOne.style.display = 'block';
                wrapper.classList.remove('is-open');
                console.log('in here')
            } else {
                artistTwo.classList.add('is-active');
                artistOne.style.display = 'none';
                wrapper?.classList.add('is-open');
                console.log('in here')
            }
        });
    }
}